import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fade from '@material-ui/core/Fade';
import SideDrawer from "../components/drawer";
import { Grid, Container, Card, CardContent, CardActions } from "@material-ui/core"
import UilExternalLinkAlt from "@iconscout/react-unicons/icons/uil-external-link-alt";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilCode from "@iconscout/react-unicons/icons/uil-code";
import "./services.css";
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';

export default ({ data }) => {
  const services = data.nodeDashboard.relationships.field_services;
  const dashboard_path = data.nodeDashboard.path.alias
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [popperContent, setPopperContent] = React.useState();

  const handleClick = content => event => {
    setAnchorEl(event.currentTarget);
    setPopperContent(content);
    setOpen(prev => placement !== 'bottom' || !prev);
    setPlacement('bottom');
  };

  const DynamicIcon = ({ icon }) => {
    let resolved = require(`@iconscout/react-unicons/icons/${icon}`).default
    let props = {};
    return React.createElement(resolved, props)
}

  return <Layout>
    <SEO title={'Services'} />
    <Grid container>
    
      <Grid item xs={2}>
        <SideDrawer context={data.nodeDashboard}></SideDrawer>
      </Grid>
      <Grid item xs={10}>
        {/* <h1>{record.nodeDataType.title}</h1> */}
        <Container>
        <div className="page">  
        <h1 className="page-title">Services</h1>
        <Divider className="page-title-hr"></Divider>
        </div>  
        <Popper style={{zIndex:10000}} open={open} anchorEl={anchorEl}>
            <div className="service-card">
              <a style={{float:'right'}} onClick={() => setOpen(false)}><UilTimes/></a>
              <pre>{popperContent}</pre>
            </div>
        </Popper>
          <Grid container spacing={2}>
            {services.map((service) => {
              return <Grid item xs={6}>
                <Paper className="service-card">
                  
                  <h2>{service.title}</h2>
                  <Typography dangerouslySetInnerHTML={{__html: service.body.processed}}/>
                  {service.relationships.field_service_links.map((link) => { 
                    return <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="service-panel"
                    >
                      <DynamicIcon icon={link.field_icon} />&nbsp;<b>{link.field_service_url.title}</b>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    <Typography dangerouslySetInnerHTML={{__html: link.field_description.processed}}/>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions className="panel-actions">
                    { link.field_code_example ? <a style={{color: "rgb(48, 48, 48)"}} onClick={handleClick(link.field_code_example)}><UilCode/></a> : null}
                    <a target="_blank" href={link.field_service_url.uri} size="small" style={{color: data.nodeDashboard.field_color[0].color}} replace>
                      <UilExternalLinkAlt/>
                    </a>
                    
                  </ExpansionPanelActions>
                  
                  </ExpansionPanel>
                  })
                  }
              </Paper>
              </Grid>
            }
            )}
          </Grid>
        </Container>
      </Grid>
    </Grid>

  </Layout>
}

export const query = graphql`
  query ($id: String!){
    nodeDashboard( id: {eq: $id }) {
      id
      path {
        alias
      }
      field_color {
        color
      }
      relationships {
        field_logo {
          localFile {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      relationships {
        field_services {
          title
          body {
            processed
          }
          relationships {
            field_service_links {
              field_description {
                processed
              }
              field_service_url {
                title
                uri
              }
              field_code_example
              field_icon
            }
          }
          
          
        }
      }
    }
  }
`
