import React from "react";

import {
    SearchProvider,
    WithSearch
} from "@elastic/react-search-ui";
import { Card, CardContent, CardHeader, CardActions, IconButton } from "@material-ui/core"
import { Link } from "gatsby"
import buildRequest from "../search/buildRequest";
import runRequest from "../search/runRequest";
import applyDisjunctiveFaceting from "../search/applyDisjunctiveFaceting";
import buildState from "../search/buildState";
import CountUp from "react-countup";
import UilAnalytics from "@iconscout/react-unicons/icons/uil-analytics";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import Img from "gatsby-image";
import "./image-numeric-card.css";

export default function ImageNumericCard(props) {
    const config = {
        debug: false,
        hasA11yNotifications: true,
        trackUrlState: false,
        alwaysSearchOnInitialLoad: true,
        initialState: {
            current: 1,
            resultsPerPage: 20,
            filters: props.source.relationships.field_data_filters.map((filter) => {
                return {
                    field: filter.relationships.field_data_platform_field.name,
                    values: [filter.field_data_platform_filter_value]
                }
            }),
            searchTerm: ''
        },
        onSearch: async (state) => {
            const aggregations = props.source.relationships.field_data_filters;
            const { resultsPerPage } = state;
            const requestBody = buildRequest(state, aggregations);

            const responseJson = await runRequest(requestBody);
            const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
                responseJson,
                state,
                []
            );
            return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage, false);
        }
    };
    const color = props.source.field_color[0].color;
    var n = 1.1;
    const delimiter = n.toLocaleString().substring(1, 2);

    const DynamicIcon = ({ icon }) => {
        //let resolved = require(`@iconscout/react-unicons/icons/${icon}`).default
        //let props = { style: { color: color } };
        //return React.createElement(resolved, props)
        return null;
    }
    return (
        <>
            <SearchProvider config={config}>
                <WithSearch mapContextToProps={({ totalResults, filters }) => ({ totalResults, filters })}>
                    {({ totalResults, filters }) => (

                        <Card className="image-numeric-card" style={{borderTop: '4px'}}>
                            

                            <CardContent className="card-content">
                            <Img imgStyle={{maxHeight:150}} fluid={props.source.relationships.field_logo.localFile.childImageSharp.fluid} />
                                
                            </CardContent>
                            <div className="card-actions-wrapper">
                                <CardActions  className="card-actions-counter" > 
                                    <span style={{ color: color, position: 'absolute', top: '10px', left:'15px' }}>
                                        <CountUp duration={0.5} className="card-stat" separator={delimiter} end={totalResults} />
                                    </span>
                                </CardActions>
                                <CardActions className="card-actions" disableSpacing>
                                
                                <span>
                                    <Link to={props.path+'/data-sources'+props.source.path.alias}>
                                        <UilAnalytics className="action"/>
                                    </Link>
                                    <Link to={props.path+'/search/?size=n_5_n&filters[0][field]=source&filters[0][values][0]='+filters[0].values[0]+'&filters[0][type]=any'}>
                                        <UilSearch className="action"/>
                                    </Link>
                                </span>
                                </CardActions>
                            </div>
                        </Card>
                    )}
                </WithSearch>
            </SearchProvider>
        </>
    );
}
