import React from "react"
import ReactWordcloud from "react-wordcloud";

function WordCloud (props) {
  var input = [];
  if (props.data != undefined) {
    var input = props.data[0].data
    for (var key in input) {
      var e = input[key];   // alias for efficient structure dereferencing
      e.text = e.value;
      e.value = e.count;
      delete e.count;
    }
  }
  return (props.data != undefined) ? <ReactWordcloud options={{fontFamily: '"Cabin", sans-serif'}} words={input}></ReactWordcloud>:null
}

export default WordCloud