import React from "react"
import ReactApexChart from "react-apexcharts";
import { withSearch } from "@elastic/react-search-ui";

function SeriesBar (props) {
  var input = [];
  if (props.data != undefined) {
    props.data[0].data.map(s => {
      
      input.push({ data: [s.count], name: s.value });
    });
    
    
  }
  //console.log(input);
  var options =  {
    theme: {
      monochrome: {
        enabled: true,
        color: props.color,
        shadeTo: 'light',
        shadeIntensity: 0.65
        
      }
    },
    chart: {
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          //console.log(input);
          if (props.data != undefined) {
           // props.setFilter(props.data[0].field, props.data[0].data[config.seriesIndex].value, "all");
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      dropShadow: {
        enabled: true
      }
    },
    stroke: {
      width: 0
    },
    xaxis: {
      categories: [props.title],
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.35,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [90, 0, 100]
      }
    },

    legend: {
      position: "bottom",
      horizontalAlign: "right"
    }
  };
  
  return (input) ? <ReactApexChart style={{fontFamily: 'Cabin, sans-serif'}} options={options} series={input} type="bar" height={150}/>:null
}


export default withSearch(({ filters, setFilter }) => ({ filters, setFilter }))(SeriesBar);