import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { AppBar, Toolbar} from "@material-ui/core"

const Header = ({ siteTitle }) => (
  <div>
    <AppBar className="header">
      <Toolbar className="nav-bar">
          {siteTitle}
      </Toolbar>
  </AppBar>
  <Toolbar />
  </div>
  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
