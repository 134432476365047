import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IconNumericCard from "../components/icon-numeric-card"
import SideDrawer from "../components/drawer"
import { Grid, Container, Divider } from "@material-ui/core"

export default ({ data }) => {
  const data_types = data.nodeDashboard.relationships.field_data_types;
  const dashboard_path = data.nodeDashboard.path.alias
  return <Layout>
    <SEO title={'Data types'} />
    <Grid container>
      <Grid item xs={2}>
        <SideDrawer context={data.nodeDashboard}></SideDrawer>
      </Grid>
      <Grid item xs={10}>
        <Container>
        <div className="page">  
        <h1 className="page-title">Data Types</h1>
        <Divider className="page-title-hr"></Divider>
        </div>  
          <Grid container spacing={2}>
            {data_types.map((type) => {
              return <Grid item xs={3}>
                <IconNumericCard type={type} path={dashboard_path}></IconNumericCard>
              </Grid>
            }
            )}
          </Grid>
        </Container>
      </Grid>
    </Grid>

  </Layout>
}

export const query = graphql`
  query ($id: String!){
    nodeDashboard( id: {eq: $id }) {
      id
      path {
        alias
      }
      field_color {
        color
      }
      relationships {
        field_logo {
          localFile {
            childImageSharp {
              fixed(width:100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    field_api_key
    field_data_service_url {
      uri
    }
    relationships {
      field_data_types {
        id
        path {
          alias
        }
        title
        field_color {
          color
        }
        field_icon
        relationships {
          field_data_filters {
            relationships {
              field_data_platform_field{
                name
                field_keyword
              }
            }
            field_logical_operator
            field_data_platform_filter_value
          }
          field_fields {
            relationships {
              field_data_platform_field{
                name
                field_keyword
              }
            }
          }
        }
      }
    }
    }
  }
`
