import React from "react"
import ReactApexChart from "react-apexcharts";
import { withSearch } from "@elastic/react-search-ui";

function TopDateChart ( props) {
  var input = null;
  if (props.data != undefined) {
    input = { series: [{
      name: "Number",
      data: props.data[0].data.map((date) => {
          return date.count;
        })
  }],
  options: {
    chart: {
      type: 'area',
      height: 100,
      events: {
        click: function(event, chartContext, config) {
          //props.setFilter("createdOn", config.config.labels[config.dataPointIndex], "all")
        }
      }
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 0.7,
    },
    labels: props.data[0].data.map((date) => {
      return date.value;
      }),
    colors: [props.color],
    title: {
      text: props.title,
      offsetX: 0,
      style: {
        fontSize: '50px',
        fontFamily:'Cabin, sans-serif',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    subtitle: {
      text: props.subTitle,
      offsetX: 0,
      offsetY: 60,
      style: {
        fontSize: '14px',
        fontFamily:'Cabin, sans-serif',
        cssClass: 'apexcharts-yaxis-title'
      }
    }, 
  }
}
  }
  
  return (input) ? <ReactApexChart style={{fontFamily: 'Cabin, sans-serif', marginBottom:'30px'}} options={input.options} series={input.series} type="bar" height={270} />:null
}

export default withSearch(({ filters, setFilter }) => ({ filters, setFilter }))(TopDateChart);