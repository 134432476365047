// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-dashboard-js": () => import("./../src/templates/dashboard.js" /* webpackChunkName: "component---src-templates-dashboard-js" */),
  "component---src-templates-data-sources-js": () => import("./../src/templates/data-sources.js" /* webpackChunkName: "component---src-templates-data-sources-js" */),
  "component---src-templates-data-types-js": () => import("./../src/templates/data-types.js" /* webpackChunkName: "component---src-templates-data-types-js" */),
  "component---src-templates-search-js": () => import("./../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-services-js": () => import("./../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-data-type-js": () => import("./../src/templates/data-type.js" /* webpackChunkName: "component---src-templates-data-type-js" */),
  "component---src-templates-data-source-js": () => import("./../src/templates/data-source.js" /* webpackChunkName: "component---src-templates-data-source-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

