
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Drawer, List, ListItem } from "@material-ui/core"
import UilAsterisk from "@iconscout/react-unicons/icons/uil-asterisk";
import UilDatabaseAlt from "@iconscout/react-unicons/icons/uil-database-alt";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import UilDataSharing from "@iconscout/react-unicons/icons/uil-data-sharing";
import Img from "gatsby-image";
import "./drawer.css";
import styled from "styled-components"

function SideDrawer (props) {
///${props.context.field_color[0].color} , ${props.context.field_color[1].color}

const HoverLink = styled(AniLink)`
  &:hover svg{
    color: ${(props.context.field_color) ?  props.context.field_color[0].color : '#303030'};
    transition: 0.1s;
  }
  &.active svg {
    color: ${(props.context.field_color) ?  props.context.field_color[0].color : '#303030'};
  }
`;

return <Drawer
          open={true}
          variant="permanent"
          className="nav-drawer"
          PaperProps= {{style: (props.context.field_color) ? { borderWidth:'2px', borderImage:`linear-gradient(to bottom, ${props.context.field_color[0].color} , ${props.context.field_color[1].color})`, borderImageSlice:2} : {}}}
        >
          <div className="nav-container"
          >
          <List className="nav-side">
            <ListItem className="nav nav-home">
            <HoverLink activeClassName="active" className="content" paintDrip hex={(props.context.field_color) ? props.context.field_color[0].color: "#88AB5D"} duration={0.3} to={props.context.path.alias}>
                { (props.context.relationships.field_logo) ? 
                  <Img grayscale fixed={props.context.relationships.field_logo.localFile.childImageSharp.fixed} /> : null
                }
              </HoverLink>
            </ListItem>
            <ListItem alignItems='center' className="nav nav-types">
              <HoverLink activeClassName="active" className="content" paintDrip hex={(props.context.field_color) ? props.context.field_color[0].color: "#88AB5D"} duration={0.3} to={props.context.path.alias+'/data-types'}>
                <UilAsterisk className="icon"/>
                <span>Data types</span>
              </HoverLink>
            </ListItem>
            <ListItem className="nav nav-sources">
              <HoverLink activeClassName="active" className="content" paintDrip hex={(props.context.field_color) ? props.context.field_color[0].color: "#88AB5D"} duration={0.3} to={props.context.path.alias+'/data-sources'}>
                <UilDatabaseAlt className="icon"/>
                <span>Data Registries</span>
              </HoverLink>
            </ListItem>
            <ListItem className="nav nav-search">
              <HoverLink activeClassName="active" className="content" paintDrip hex={(props.context.field_color) ? props.context.field_color[0].color: "#88AB5D"} duration={0.3} to={props.context.path.alias+'/search'}>
                <UilSearch className="icon"/>
                <span>Search</span>
              </HoverLink>
            </ListItem>
            <ListItem className="nav nav-api">
              <HoverLink activeClassName="active" className="content" paintDrip hex={(props.context.field_color) ? props.context.field_color[0].color: "#88AB5D"} duration={0.3} to={props.context.path.alias+'/services'}>
                <UilDataSharing className="icon"/>
                <span>Services</span>
              </HoverLink>
            </ListItem>
          </List>
          <p className="cp">
          © {new Date().getFullYear()}, &nbsp;
           <a target="_blank" href="http://www.agroknow.com">Agroknow</a>
          </p>
          </div>
          
        </Drawer>
}

export default SideDrawer