import React from "react";

import {
    SearchProvider,
    WithSearch
} from "@elastic/react-search-ui";
import { Card, CardContent, CardHeader, CardActions, IconButton } from "@material-ui/core"
import { Link } from "gatsby"
import buildRequest from "../search/buildRequest";
import runRequest from "../search/runRequest";
import applyDisjunctiveFaceting from "../search/applyDisjunctiveFaceting";
import buildState from "../search/buildState";
import CountUp from "react-countup";
import UilAnalytics from "@iconscout/react-unicons/icons/uil-analytics";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import "./icon-numeric-card.css";

export default function IconNumericCard(props) {

    const config = {
        debug: false,
        hasA11yNotifications: true,
        trackUrlState: false,
        alwaysSearchOnInitialLoad: true,
        initialState: {
            current: 1,
            resultsPerPage: 20,
            // filters: [
            //     {
            //         field: "entityType",
            //         values: ["Model"],
            //     }
            // ],
            filters: props.type.relationships.field_data_filters.map((filter) => {
                return {
                    field: filter.relationships.field_data_platform_field.name,
                    values: [filter.field_data_platform_filter_value]
                }
            }),
            searchTerm: ''
        },
        onSearch: async (state) => {
            const { resultsPerPage } = state;
            const requestBody = buildRequest(state);

            const responseJson = await runRequest(requestBody);
            const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
                responseJson,
                state,
                []
            );
            return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage, false);
        }
    };
    const color = props.type.field_color[0].color;
    
    var n = 1.1;
    const delimiter = n.toLocaleString().substring(1, 2);

    const DynamicIcon = ({ icon }) => {
        let resolved = require(`@iconscout/react-unicons/icons/${icon}`).default
        let props = { style: { color: color } };
        return React.createElement(resolved, props)
    }
    return (
        <>
            <SearchProvider config={config}>
                <WithSearch mapContextToProps={({ totalResults,filters }) => ({ totalResults,filters })}>
                    {({ totalResults,filters }) => (

                        <Card className="numeric-card" style={{borderTop: '4px'}}>
                            <CardActions style={{borderTop: '4px'}} className="data-type"><DynamicIcon icon={props.type.field_icon} /> {props.type.title}</CardActions>

                            <CardContent className="card-content">
                                <span style={{ color: color }}><CountUp duration={0.5} separator={delimiter} className="card-stat" end={totalResults} /></span>
                            </CardContent>
                            <CardActions className="card-actions" disableSpacing>
                                <Link to={props.path+'/data-types'+props.type.path.alias}>
                                    <UilAnalytics className="action"/>
                                </Link>
                                <Link to={props.path+'/search/?size=n_5_n&filters[0][field]=entityType&filters[0][values][0]='+filters[0].values[0]+'&filters[0][type]=any'}>
                                    <UilSearch className="action"/>
                                </Link>
                            </CardActions>
                        </Card>
                    )}
                </WithSearch>
            </SearchProvider>
        </>
    );
}
