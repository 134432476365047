import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SideDrawer from "../components/drawer"
import SEO from "../components/seo"
import buildRequest from "../search/buildRequest";
import runRequest from "../search/runRequest";
import applyDisjunctiveFaceting from "../search/applyDisjunctiveFaceting";
import buildState from "../search/buildState";
import { Card, CardContent, Divider } from "@material-ui/core"
import {
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";

import CountUp from "react-countup";
import { Grid, Container } from "@material-ui/core"


export default ({ data }) => {
  var topChart = {};
  const record = data;
  const color = data.nodeDataType.field_color[0].color;
  var aggregations = data.nodeDataType.relationships.field_fields.sort( function(a,b) {
    if (a.field_sticky_top > b.field_sticky_top) {
      return -1;
    }
    if (a.field_sticky_top < b.field_sticky_top) {
      return 1;
    }
    return 0;
  });
  if (aggregations[0].field_sticky_top) {
    topChart = aggregations[0];
  }
  var data_filters = data.nodeDataType.relationships.field_data_filters.map((filter) => {
    return {
        field: filter.relationships.field_data_platform_field.name,
        values: [filter.field_data_platform_filter_value]
    }
  });
  // if (data.nodeDataType.relationships.field_date_analysis != null) {
  //   data_filters.push({
  //     field: data.nodeDataType.relationships.field_date_analysis.name,
  //     values: [],
  //     type: "date"
  //   })
  // }
  const config = {
    debug: false,
    hasA11yNotifications: true,
    trackUrlState: true,
    alwaysSearchOnInitialLoad: true,
    initialState: {
        current: 1,
        resultsPerPage: 5,
        filters: data_filters,
        searchTerm: ''
    },
    onSearch: async (state) => {
        const { resultsPerPage } = state;
        const requestBody = buildRequest(state, aggregations);
  
        const responseJson = await runRequest(requestBody);
        const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
            responseJson,
            state,
            []
        );
        return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage, false);
    }
  };

  return <Layout>
    <SEO title={record.nodeDataType.title} />
    <Grid container>
      <Grid item xs={2}>
        <SideDrawer context={data.nodeDataType.relationships.node__dashboard[0]}></SideDrawer>
      </Grid>
      <Grid item xs={10}>
        <Container maxWidth="lg">
          
        <div className="page">  
        <h1 className="page-title">{record.nodeDataType.title}</h1>
        <Divider className="page-title-hr"></Divider>
        </div>  
        <SearchProvider config={config}>
          <WithSearch mapContextToProps={({ totalResults, setFilter, filters, facets }) => ({ totalResults, setFilter, filters, facets })}>
            {({ totalResults, setFilter, filters, facets }) => {
              var topChartComponent = null;
              if (topChart.relationships) {
                let resolved = require(`../components/visualizations/${topChart.relationships.field_visualization.field_component.toLowerCase()}`).default
                let props = { data: facets[topChart.relationships.field_data_platform_field.name], color: color, title: totalResults+"", subTitle: topChart.field_title, filters:filters, setFilter:setFilter};
                topChartComponent = React.createElement(resolved, props);
              }
               return <Grid container spacing={3}>
                {(topChart != null) ?  <Grid item wrap="nowrap" xs={12}>
                      <Card className="numeric-card" style={{height:'300px'}}>
                        <CardContent className="card-content">
                         {topChartComponent}
                         </CardContent>
                      </Card>
                    </Grid>
                  : <Grid item xs={6}><CountUp className="card-stat" end={totalResults} /></Grid>  
                }
                
                {
                  aggregations.map((agg) => {
                    if (agg.relationships.field_visualization != undefined && !agg.field_sticky_top ) {
                      let resolved = require(`../components/visualizations/${agg.relationships.field_visualization.field_component.toLowerCase()}`).default
                      let props = { data: facets[agg.relationships.field_data_platform_field.name], color: color, title: agg.field_title, filters:filters, setFilter:setFilter};
                    return <Grid item wrap="nowrap" xs={parseInt(agg.field_visualization_width)}>
                        <Card  className="numeric-card">
                          <CardContent className="card-content">
                           <h2>{agg.field_title}</h2> 
                            {React.createElement(resolved, props)}</CardContent>
                        </Card>
                      </Grid>
                    
                    }
                    
                  })
                }
              </Grid>
            
          }
          }
          </WithSearch>

        </SearchProvider>
        </Container>
      </Grid>
    </Grid>
  </Layout>
}

export const query = graphql`
  query($id: String!) {
    nodeDataType( id: {eq: $id}) {
      id
      title
      
      field_color {
        color
        opacity
      }
      relationships {
        
        field_fields {
          field_title
          relationships {
            field_data_platform_field {
              name
              field_keyword
              field_date
            }
            field_visualization {
              field_component
              field_input_parameter
            }
          }
          field_sticky_top
          field_visualization_width
        }
        field_data_filters {
          relationships {
            field_data_platform_field {
              name
              field_keyword
            }
          }
          field_logical_operator
          field_data_platform_filter_value
        }
        node__dashboard {
          id
          path {
            alias
          }
          field_color {
            color
          }
          relationships {
            field_logo {
              localFile {
                childImageSharp {
                  fixed(width:100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
