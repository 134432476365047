import React from "react"
import ReactApexChart from "react-apexcharts";
import { withSearch } from "@elastic/react-search-ui";

function Pie (props) {
  var input = null;
  if (props.data != undefined) {
    input = {
      style: {
        fontFamily: 'Cabin, sans-serif'
        },
      options: {
        theme: {
          monochrome: {
            enabled: true,
            color: props.color,
            shadeTo: 'light',
            shadeIntensity: 0.65
            
          }
        },
        chart: {
          events: {
            dataPointSelection: function (event, chartContext, config) {
              props.setFilter(props.data[0].field, props.data[0].data[config.dataPointIndex].value, "all")
            }
          }
        },
        labels: props.data[0].data.map((group) => {
          return group.value;
        }),
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series: props.data[0].data.map((group) => {
        return group.count;
      }),
    }
  }
  
  return (input) ? <ReactApexChart style={{fontFamily: 'Cabin, sans-serif'}} options={input.options} series={input.series} type="pie" width="380" />:null
}


export default withSearch(({ filters, setFilter }) => ({ filters, setFilter }))(Pie);