import React, {useRef} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SideDrawer from "../components/drawer"
import SEO from "../components/seo"
import Chip from "@material-ui/core/Chip"
import buildRequest from "../search/buildRequest";
import runRequest from "../search/runRequest";
import applyDisjunctiveFaceting from "../search/applyDisjunctiveFaceting";
import buildState from "../search/buildState";

import UilExternalLinkAlt from "@iconscout/react-unicons/icons/uil-external-link-alt";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  WithSearch,
  SearchBox,
  PagingInfo,
  ResultsPerPage,
  SingleSelectFacet,
  Paging
} from "@elastic/react-search-ui";
import SmartToggle from "../search/components/SmartToggle";
import SemanticTreeToggle from "../search/components/SemanticTreeToggle";
import { Grid, Container, Card, CardContent, CardActions, Box, LinearProgress, Typography, Button, Collapse } from "@material-ui/core";
import ClampLines from 'react-clamp-lines';
import "./search.css";


export default ({ data }) => {
  const record = data;
  const ref = useRef();
//   const data_types_filters = data.nodeDashboard.relationships.field_data_types.map((type) => {
//     return type.relationships.field_data_filters.map((filter) => {
//       return {
//           field: filter.relationships.field_data_platform_field.name,
//           values: [filter.field_data_platform_filter_value]
//       }
//   })
// });
// const data_sources_filters = data.nodeDashboard.relationships.field_data_sources.map((source) => {
//   return source.relationships.field_data_filters.map((filter) => {
//     return {
//         field: filter.relationships.field_data_platform_field.name,
//         values: [filter.field_data_platform_filter_value]
//     }
// })
// });
const data_filters = [];
  const config = {
    debug: false,
    hasA11yNotifications: true,
    trackUrlState: true,
    alwaysSearchOnInitialLoad: true,
    initialState: {
        current: 1,
        resultsPerPage: 5,
        filters: [],/*data.nodeDataType.relationships.field_data_filters.map((filter) => {
            return {
                field: filter.relationships.field_data_platform_field.name,
                values: [filter.field_data_platform_filter_value]
            }
        }),*/
        searchTerm: ''
    },
    onSearch: async (state) => {
        const { resultsPerPage } = state;
        const requestBody = buildRequest(state);

        const responseJson = await runRequest(requestBody);
        const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
            responseJson,
            state,
            []
        );
        return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage, false);
    }
  };

  return <Layout>
    <SEO title="Search" />
    <Grid container className="search">
      <Grid item xs={2}>
        <SideDrawer context={data.nodeDashboard}></SideDrawer>
      </Grid>
      <Grid item xs={10}>
        <Container>
        <SearchProvider config={config}>
          <WithSearch mapContextToProps={({ isLoading, wasSearched, results, filters, searchTerm, setSearchTerm }) => ({ isLoading, wasSearched, results, filters, searchTerm, setSearchTerm })}>
            {({ isLoading, wasSearched, results, filters, searchTerm, setSearchTerm }) => (

              <Grid container spacing={3}>

                <ErrorBoundary>

                  <Grid item xs={12}>

                    <SearchBox
                      alwaysSearchOnInitialLoad={true}
                      autocompleteResults={false}
                      autocompleteSuggestions={false}
                      searchAsYouType={false}
                    />

                  </Grid>
                  {wasSearched && (
                    <Grid container spacing={3}>
                      <Grid item xs={3}><Box style={{ marginBottom: '20px' }}>

                        {!isLoading && <ResultsPerPage />}
                        <SmartToggle />
                      </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box style={{ float: 'right', textAlign: 'right' }}>
                          {!isLoading && <PagingInfo />}<br />
                          <Paging />

                        </Box>

                      </Grid>
                      <Grid item xs={12}>
                        {isLoading && <LinearProgress className="progressBar" variant="query" />}
                      </Grid>
                      <>
                        <Grid item xs={3}>
                          <Facet
                            field="tags"
                            label="Tags"
                            filterType="any"
                            isFilterable={true}
                          />
                          <Facet
                            field="entityType"
                            label="Type"
                            filterType="any"
                          />
                          <Facet
                            field="date"
                            label="Dates"
                            filterType="any"
                          />
                          <Facet
                            field="source"
                            label="Registry"
                            filterType="any"
                            view={SingleSelectFacet}
                          />
                          <Facet
                            field="organization"
                            label="Organization"
                            filterType="any"
                          />
                          
                        </Grid>
                        <Grid item xs={9}>
                          {results.map(result => {
                            
                            return (
                              <Card className="resultCard" key={result.id.raw}>
                                <CardContent>

                                  <Typography className="title" color="textSecondary" gutterBottom>
                                    {result.title.raw}
                                    <b>{(result._score) ? "null" : result._score}</b>
                                  </Typography>

                                  <Typography className="secondaryTitle" color="textSecondary">
                                    {result.information.raw.type} - {new Date(result.createdOn.raw).getFullYear()}
                                  </Typography>
                                  {(result.description.raw) &&
                                  <ClampLines
                                    text={result.description.raw}
                                    id="default"
                                    lines={3}
                                    className="description"
                                    ellipsis="..."
                                    moreText="read more"
                                    lessText="read less"
                                    innerElement="p" 
                                  />
                                  }
                                  <div class="tags">
                                    {(result.tags.raw) &&
                                      result.tags.raw.map(tag => {
                                        return (<Chip className="tag" size="small" label={tag} onClick={()=>{setSearchTerm (tag);}}/>)
                                      })
                                    }
                                  </div>
                                </CardContent>
                                <CardActions>
                                  {result.tags.raw ? 
                                    <SemanticTreeToggle query={result.title.raw + ' ' + result.description.raw + ' ' + result.tags.raw.toString()} disabled={
                                      filters.filter(filter => filter.field == 'smart').length == 0 ||
                                      !filters.filter(filter => filter.field == 'smart')[0].values[0]
                                    }
                                    /> : null
                                  }

                                  <Button target="_blank" href={result.information.raw["url"]} size="small"><UilExternalLinkAlt/>&nbsp;Access</Button>
                                </CardActions>
                              </Card>
                            )
                          }
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Paging style={{ float: 'right' }} />
                        </Grid>
                      </>
                    </Grid>
                  )}
                </ErrorBoundary>

              </Grid>
            )}
          </WithSearch>

        </SearchProvider>
        </Container>
      </Grid>
    </Grid>
  </Layout>
}

export const query = graphql`
  query ($id: String!){
    nodeDashboard( id: {eq: $id }) {
      id
      title
      path {
        alias
      }
    field_api_key
    field_data_service_url {
      uri
    }
    field_color {
      color
    }
    relationships {
      field_logo {
        localFile {
          childImageSharp {
            fixed(width:100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      field_data_types {
        id
        path {
          alias
        }
        title
        relationships {
          field_data_filters {
            relationships {
              field_data_platform_field{
                name
                field_keyword
                
              }
            }
            field_logical_operator
            field_data_platform_filter_value
          }
          
        }
      }
      field_data_sources {
        id
        path {
          alias
        }
        title
        relationships {
          field_data_filters {
            relationships {
              field_data_platform_field{
                name
                field_keyword
                
              }
            }
            field_logical_operator
            field_data_platform_filter_value
          }
        }
      }
    }
  }
  }
`
