function getValueFacet(aggregations, fieldName, prefix) {
  
  if (
    aggregations &&
    aggregations[prefix+fieldName] &&
    aggregations[prefix+fieldName].buckets &&
    aggregations[prefix+fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[prefix+fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || bucket.key,
          count: bucket.doc_count
        }))
      }
    ];
  }
}

function getRangeFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations['date_histogram#'+fieldName] &&
    aggregations['date_histogram#'+fieldName].buckets &&
    aggregations['date_histogram#'+fieldName].buckets.length > 0
  ) {
    return [
      {
        field: 'date_histogram#'+fieldName,
        type: "range",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: {
            to: bucket.to,
            from: bucket.from,
            name: bucket.key
          },
          count: bucket.doc_count
        }))
      }
    ];
  }
}

function getSearchAPIconfig( configValue, fieldName ) {
  // alert(configValue);
}

export default function buildStateFacets(aggregations, maxScore) {
  const smart = getSearchAPIconfig(
    (maxScore == undefined),
    "smart"
  )
  // const tags = getValueFacet(
  //   aggregations,
  //   "tags"
  // );
  // const entityType = getValueFacet(
  //   aggregations,
  //   "entityType"
  // );
  // const dataSource = getValueFacet(
  //   aggregations,
  //   "dataSource"
  // );
  const facets = {};
  Object.keys(aggregations).forEach(function(key) {
    if (key.indexOf("sterms#") != -1) {
      facets[key.replace("sterms#","")] = getValueFacet(aggregations, key.replace("sterms#",""), "sterms#")
    }
    else {
      facets[key.replace("date_histogram#","")] = getValueFacet(aggregations, key.replace("date_histogram#",""), "date_histogram#")
    }
  });
  // const facets = {
  //   ...(tags && { tags }),
  //   ...(entityType && { entityType }),
  //   ...(dataSource && { dataSource }),
  //   ...(smart && { smart })
  // };
  if (Object.keys(facets).length > 0) {
    return facets;
  }
}
