import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SideDrawer from "../components/drawer"
import SEO from "../components/seo"
import { Grid, Container, Divider, Paper } from "@material-ui/core"
import buildRequest from "../search/buildRequest";
import runRequest from "../search/runRequest";
import applyDisjunctiveFaceting from "../search/applyDisjunctiveFaceting";
import buildState from "../search/buildState";

import {
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import Img from "gatsby-image"
import "./dashboard.css";
import CountUp from "react-countup";
import UilAsterisk from "@iconscout/react-unicons/icons/uil-asterisk";
import UilDatabaseAlt from "@iconscout/react-unicons/icons/uil-database-alt";
import UilDataSharing from "@iconscout/react-unicons/icons/uil-data-sharing";

export default ({ data }) => {

  var n = 1.1;
  const delimiter = n.toLocaleString().substring(1, 2);

  const config = {
    debug: false,
    hasA11yNotifications: true,
    trackUrlState: false,
    alwaysSearchOnInitialLoad: true,
    initialState: {
        current: 1,
        resultsPerPage: 20,
        // filters: [
        //     {
        //         field: "entityType",
        //         values: ["Model"],
        //     }
        // ],
        filters: [],
        searchTerm: ''
    },
    onSearch: async (state) => {
        const { resultsPerPage } = state;
        const requestBody = buildRequest(state);

        const responseJson = await runRequest(requestBody);
        const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
            responseJson,
            state,
            []
        );
        return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage, false);
    }
};

  return <Layout>
    <SEO title={data.nodeDashboard.title} />
    <Grid container>
      <Grid item xs={2}>
        <SideDrawer context={data.nodeDashboard}></SideDrawer>
     </Grid>
     <Grid item xs={10}>
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          {
            (data.nodeDashboard.relationships.field_logo) && 
            <Img fluid={data.nodeDashboard.relationships.field_logo.localFile.childImageSharp.fluid} />
          }
        </Grid>
        <Grid item xs={8} className="welcome-placeholder">
          <h1>{data.nodeDashboard.title}</h1>
          <p className="welcome-text" dangerouslySetInnerHTML={{ __html: data.nodeDashboard.body.processed }}/>
        </Grid>
      </Grid>
      
      <Divider variant="middle" className="welcome-divider"/>
      <SearchProvider config={config}>
            <WithSearch mapContextToProps={({ totalResults }) => ({ totalResults })}>
            {({ totalResults }) => (
              <div className="welcome-total"><CountUp duration={1} separator={delimiter} end={totalResults} style={{ color: data.nodeDashboard.field_color[0].color }} /> records</div>
            
              )}  
            </WithSearch>
      </SearchProvider>
      <hr class="brace"/>

      <Grid container alignContent="center" alignItems="center" spacing={3} className="welcome-stat-wrapper">
        {data.nodeDashboard.relationships.field_data_types.length > 0 ? 
          <Grid item xs>
            <Paper className="welcome-stat">
              <div className="stat" style={{color: data.nodeDashboard.field_color[1].color}}><UilAsterisk className="icon"/> <CountUp duration={1} end={data.nodeDashboard.relationships.field_data_types.length } /></div>
              <div className="text">Data types</div>
              </Paper>
          </Grid>
        : null }
        {data.nodeDashboard.relationships.field_data_sources.length > 0 ? 
          <Grid item xs>
            <Paper className="welcome-stat">
              <div className="stat" style={{color: data.nodeDashboard.field_color[1].color}}><UilDatabaseAlt className="icon"/> <CountUp duration={1} end={data.nodeDashboard.relationships.field_data_sources.length } /></div>
              <div className="text">Data registries</div>
              </Paper>
          </Grid>
        : null }
        {data.nodeDashboard.relationships.field_services.length > 0 ? 
          <Grid item xs>
            <Paper className="welcome-stat">
              <div className="stat" style={{color: data.nodeDashboard.field_color[1].color}}><UilDataSharing className="icon"/> <CountUp duration={1} end={data.nodeDashboard.relationships.field_services.length } /></div>
              <div className="text">Services</div>
            </Paper>
          </Grid>
        : null }
      </Grid>
    </Container>
    <Container>
          <div className="access-details">
            <div dangerouslySetInnerHTML={{ __html: data.nodeDashboard.field_access_details.processed }}/>
          </div>
    </Container>
    </Grid>
</Grid>
  </Layout>
}

export const query = graphql`
  query ($id: String!){
    nodeDashboard( id: {eq: $id }) {
      id
      title
      body {
        value
        format
        processed
        summary
      }
      path {
        alias
      }
    field_api_key
    field_data_service_url {
      uri
    }
    field_color {
      color
    }
    field_access_details {
      processed
    }
    relationships {
      field_logo {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      field_data_types {
        id
        path {
          alias
        }
        title
      }
      field_data_sources {
        id
        path {
          alias
        }
        title
      }
      field_services {
        id
      }
    }
  }
  }
`
